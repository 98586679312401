<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Salesman</label>
                    <Dropdown dataKey="mcu_id" v-model="forms.mcu_id" :loading="loadingDropdownSalesman"
                        :options="dataDropdownSalesman" :class="{ 'p-invalid': errorAdd.mcu_id }"
                        optionLabel="label" optionValue="mcu_id" placeholder="Select Salesman"
                        :filter="true" :showClear="true" @filter="searchDropdownSalesman($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.mcu_id">{{
                        errorAdd.mcu_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Distributor</label>
                    <Dropdown dataKey="db_id" v-model="forms.db_id" :loading="loadingDropdownDistributor"
                        :options="dataDropdownDistributor" :class="{ 'p-invalid': errorAdd.db_id }"
                        optionLabel="label" optionValue="db_id" placeholder="Select Distributor"
                        :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.db_id">{{
                        errorAdd.db_id[0]
                }}</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="period">Date</label>
                <Calendar :manualInput="false" v-model="period" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";
import moment from 'moment';

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownDistributor: false,
            loadingDropdownSalesman: false,

            // dataDropdown
            dataDropdownDistributor: null,
            dataDropdownSalesman: null,

            // addNew
            forms: {
                db_id: null,
                mcu_id: null,
            },

            period: new Date(),
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownSalesman('');
        this.searchDropdownDistributor('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),

        period_start(){ 
            return moment(this.period, 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownSalesman(event, purpose, valueEdit){
            setTimeout(() => {
                    
                    if(valueEdit){
                        this.$refs.dsalesman.filterValue = valueEdit;
                    }

                    if(purpose == "add"){
                        this.loadingDropdownSalesman = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/mcu2',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                        }
                    })
                    .then(res => {

                        if(purpose == "add"){
                            this.dataDropdownSalesman = res.data.data;
                            this.loadingDropdownSalesman = false;
                        }else if(purpose == null){
                            this.dataDropdownSalesman = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        searchDropdownDistributor(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.ddistributor.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownDistributor = res.data.data;
                        this.loadingDropdownDistributor = false;
                    }else if(purpose == null){
                        this.dataDropdownDistributor = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/pjp/create',
                data: {
                    "db_id": this.forms.db_id,
                    "mcu_id": this.forms.mcu_id,
                    "date": this.period_start
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.db_id = null;
            this.forms.mcu_id = null;
            this.period = new Date();
        },
    }
}
</script>